{
  "pt-BR": {
    "javascript": {
      "validator": {
        "invalid_age": "Não é possível realizar o cálculo com as datas fornecidas",
        "invalid_date": "Esta data é inválida, mas poderá ser salva dada a possibilidade de inconsistência do cadastro",
        "invalid_hour": "Este horário é inválido, mas poderá ser salvo dado a possibilidade de inconsistência do cadastro"
      }
    }
  }
}