import { Controller } from "@hotwired/stimulus"
import { validateDate } from "../services/date_validator";
import { validateHour } from "../services/hour_validator";
import { validateAge } from "../services/age_validator";
import translations from "../locales/pt-BR.json";

export default class extends Controller {
  static targets = ["message"]

  validators = {
    date: validateDate,
    hour: validateHour,
    age:  validateAge,
  };

  connect() {
    const input = this.element.getElementsByTagName("input")[0];

    input.addEventListener("input", (event) => { this.validate(event) });
    input.addEventListener("blur", (event) => { this.validate(event) });
  };

  async validate(event) {
    var eventTarget = event.target;
    var eventType   = event.type;

    if (!this.hasMessageTarget) eventTarget.parentElement.insertAdjacentElement("afterend", this.#messageElement());

    const validatorFunction = this.validators[this.#kind()];

    if (validatorFunction) {
      this.#isValid(await validatorFunction(eventTarget, eventType), eventTarget);
    } else {
      this.#isValid(false);
    };
  };

  #kind() {
    return this.element.dataset.stimulusValidationKind;
  };

  #messageElement() {
    const message = document.createElement("div");

    message.style = "text-wrap: wrap; width: 100%; margin-top: 0.25rem; font-size: 0.875em; color: var(--bs-form-invalid-color);";
    message.innerText = translations["pt-BR"]["javascript"]["validator"][`invalid_${this.#kind()}`];
    message.setAttribute("hidden", "");
    message.dataset["stimulusValidationTarget"] = "message";

    return message;
  };

  #isValid(isValid){
    if (isValid) {
      this.messageTarget.setAttribute("hidden", "");
    } else {
      this.messageTarget.removeAttribute("hidden");
    };
  };
};
